import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IQuiz } from 'modules/quiz/models/quiz.model';
import { QuizService } from 'modules/quiz/services/quiz.service';
import { Unsubscribable, catchError } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'preview-quiz-modal',
  templateUrl: './preview-quiz.component.html',
})
export class PreviewQuizComponent implements OnInit, OnDestroy {
  quiz?: IQuiz;
  quizTypeName: string;
  reload = false;
  previewError: string;
  reloading = false;
  private subscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private quizService: QuizService,
  ) {}

  ngOnInit() {
    if (this.reload) {
      this.reloading = true;
      this.subscriber = this.quizService
        .get(this.quiz.id, null, true)
        .pipe(
          finalize(() => {
            this.subscriber?.unsubscribe();
            delete this.subscriber;
            this.reloading = false;
          }),
          catchError(() => {
            this.previewError = 'Failed to load quiz';

            return null;
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.quiz = result as IQuiz;
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
