import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IHelpText } from 'modules/help-texts/models/help-text.model';
import { HelpTextService } from 'modules/help-texts/services/help-text.service';
import { Unsubscribable, finalize } from 'rxjs';

@Component({
  selector: 'help-text-component',
  templateUrl: './help-text.component.html',
})
export class HelpTextComponent implements OnInit, OnDestroy {
  @Input() siteSettingId?: number;
  loading = false;
  helpText?: IHelpText;
  private subscriber?: Unsubscribable;

  constructor(private helpTextService: HelpTextService) {}

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }

  ngOnInit(): void {
    if (this.siteSettingId) {
      this.loading = true;

      this.subscriber = this.helpTextService
        .get({ siteSettingId: this.siteSettingId })
        .pipe(
          finalize(() => {
            this.loading = false;
            this.subscriber?.unsubscribe();
            this.subscriber = null;
          }),
        )
        .subscribe((helpText) => (this.helpText = helpText));
    }
  }
}
