import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { IFileUploadOptions } from 'components/file-upload/file-upload.model';
import { IUploadedTmpFile } from 'components/file-upload/single-file-upload.upgraded';
import { GlobalConfig } from 'core/environment';
import { questionTypeExtension } from 'modules/quiz';
import { IAnswerVariant, IQuestion } from 'modules/quiz/models/quiz.model';
import { QuizTemporaryFilesService } from 'modules/quiz/services/temporary-files.service';

@Component({ template: '' })
export abstract class EditAnswerComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() answer: IAnswerVariant;
  @Input() scope: boolean;
  @Input() otherEditable: boolean;
  @Output() saveHandler: EventEmitter<IAnswerVariant> = new EventEmitter();
  @Output() cancelHandler: EventEmitter<void> = new EventEmitter();

  answerImage: IUploadedTmpFile;
  fileUploadOptions: IFileUploadOptions;

  questionTypeExtension = questionTypeExtension;

  answerDraft: IAnswerVariant;
  fileUploadProgress = false;

  notificationService: NotificationService;
  globalConfig: GlobalConfig;
  quizTemporaryFilesService: QuizTemporaryFilesService;

  constructor(injector: Injector) {
    this.notificationService = injector.get(NotificationService);
    this.quizTemporaryFilesService = injector.get(QuizTemporaryFilesService);
    this.globalConfig = injector.get(GlobalConfig);
  }

  ngOnInit() {
    this.answerDraft = Object.assign({}, this.answer);

    this.activate();
    this.answerImage = this.quizTemporaryFilesService.getStoredFile(this.answerDraft.tmpImageFile?.tmpName);
  }

  submit(form: NgForm) {
    if (!this.question.typeId) {
      return;
    }

    if (form.invalid) {
      this.notificationService.error('Please fill in all required fields', 2e3);

      return;
    }

    this.processTmpImage();
    this.saveHandler.next(this.answerDraft);
  }

  cancel() {
    this.cancelHandler.next();
  }

  processTmpImage() {
    if (this.answerImage) {
      this.quizTemporaryFilesService.storeFile(this.answerImage);
      this.answerDraft.tmpImageFile = { tmpName: this.answerImage.name };
    } else {
      this.answerDraft.tmpImageFile = null;
    }
  }

  initUploadOptions() {
    this.fileUploadOptions = {
      endPoint: '/a/quizzes/media/' + this.question.globalId + '/temp_image/?fullsize', // We can't remove images from server because changes can be discarded.
      acceptFileTypes: 'image/gif,image/jpeg,image/png,image/bmp',
    };
  }

  activate(): void {
    this.initUploadOptions();
  }
}
