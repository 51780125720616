import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import { ErrorHandlerService } from 'core/services';
import _ from 'lodash';
import { IQuizSearchItem } from 'modules/admin/quiz/models/quiz.model';
import { QuizManagerService } from 'modules/admin/quiz/services/quiz-manager.service';
import { ICourseQuizTypeCategory } from 'modules/course/quiz/models/course-quiz.models';
import { QuizType } from 'modules/quiz';
import { SearchServiceUpgraded, SearchUpgradedProvider } from 'modules/search/services';

@Component({
  selector: 'quizzes-search',
  templateUrl: './quizzes-search.component.html',
})
export class QuizzesSearchComponent implements OnInit, OnDestroy {
  static readonly selector = 'quizzesSearch';
  @Input() quizTypeCategoryId: QuizType;

  query = '';
  reverse: boolean;
  predicate: string;
  itemCount: number;
  currentPage: number;
  showInactive: boolean;
  searchRequestPromise: boolean;
  items: IQuizSearchItem[];
  allIItemCount: number;
  sortingEnabled = true;

  quizTypeCategory: ICourseQuizTypeCategory;
  itemsPerPage = 10;
  readonly defaultSorting = { field: 'title', dir: 'asc', reverse: false };
  private urlHandlerDestroy?: UrlParamHandler;

  private urlParams: IUrlParams;
  private searchService: SearchServiceUpgraded<IQuizSearchItem>;

  constructor(
    private urlService: UrlService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
    private searchProvider: SearchUpgradedProvider,
    private quizManagerService: QuizManagerService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.searchService = this.searchProvider.get<IQuizSearchItem>({
      initialRequestItemCount: this.itemsPerPage,
      sequentRequestItemsCount: this.itemsPerPage,
      mode: 'admin',
    });
  }

  ngOnInit(): void {
    this.bindQuizType();
    this.getAllItemsCount().then(() => {
      this.ngOnUrlChange();
      this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
    });
  }

  ngOnDestroy(): void {
    if (this.urlHandlerDestroy) {
      this.urlHandlerDestroy();
    }
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.ngOnUrlParamsChange(params);
    }
  }

  ngOnUrlParamsChange(params: IUrlParams<string>) {
    const page = Number(params.page) || 1;

    this.query = params.query;
    this.showInactive = params.showInactive?.toString() !== 'false';
    this.currentPage = page;
    this.predicate = params.sort_field || this.defaultSorting.field;
    this.reverse = (params.sort_dir || this.defaultSorting.dir) === 'desc';
    this.sortingEnabled = !this.query;

    this.search(params);
  }

  bindQuizType() {
    this.quizManagerService.getTypeCategory(this.quizTypeCategoryId).subscribe((category) => {
      this.quizTypeCategory = category;
    });
  }

  applySearchFilters() {
    if (this.query !== this.urlParams.query) {
      const filters = Object.assign({}, this.urlParams, { query: this.query, page: null });

      this.stateService.go(this.activeState.current, filters);
    } else {
      this.search(this.urlParams);
    }
  }

  changeStatus() {
    const filters = Object.assign({}, this.urlParams, {
      showInactive: !this.showInactive ? null : false,
      page: null,
    });

    this.stateService.go(this.activeState.current, filters);
  }

  getAllItemsCount() {
    const searchQuery = {
      type: 'quiz',
      type_id: this.quizTypeCategoryId,
      stat_only: true,
      count: 1,
    };

    return this.searchService
      .search(searchQuery)
      .then(({ data }) => {
        this.allIItemCount = data.count;
      })
      .catch((reason) => this.errorHandlerService.handleForbiddenError(reason));
  }

  actionsHandler() {
    this.getAllItemsCount().then(() => {
      if (this.allIItemCount > 0) {
        this.search(this.urlParams);
      } else {
        this.items = null;
        this.itemCount = null;
      }
    });
  }

  applySort(key: string) {
    const reverse = this.predicate === key ? !this.reverse : this.defaultSorting.reverse;
    const filters = Object.assign({}, this.urlParams, {
      sort_field: key === this.defaultSorting.field ? null : key,
      sort_dir: reverse === this.defaultSorting.reverse ? null : reverse ? 'desc' : 'asc',
    });

    this.stateService.go(this.activeState.current, filters);
  }

  pageChanged(page: number) {
    const filters = Object.assign({}, this.urlParams, { page: (page > 1 && page) || null });

    this.stateService.go(this.activeState.current, filters);
  }

  private search(params: IUrlParams) {
    const query = Object.assign({}, params, {
      type: 'quiz',
      sort: this.sortingEnabled ? this.predicate : 'relevance',
      sort_dir: this.reverse ? 'desc' : 'asc',
      rows: this.itemsPerPage,
      type_id: this.quizTypeCategoryId,
      startDoc: (this.currentPage - 1) * this.itemsPerPage || 0,
      showInactive: this.showInactive,
    });

    this.searchRequestPromise = true;
    this.searchService
      .search(query)
      .then(({ data }) => {
        this.itemCount = data.count;
        this.items = data.items;
      })
      .catch((reason) => this.errorHandlerService.handleForbiddenError(reason))
      .finally(() => (this.searchRequestPromise = false));
  }
}
