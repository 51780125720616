import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { GlobalConfig } from 'core/environment';
import _ from 'lodash';
import { IQuizQuestionEvent } from 'modules/admin/quiz/question';
import { QuizQuestionsManagerService } from 'modules/admin/quiz/question/services/quiz-questions-manager.service';
import { IQuestion, IQuiz, IQuizQuestion } from 'modules/quiz/models/quiz.model';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { QuizQuestionService } from 'modules/quiz/services/quiz-question.service';
import { ITMPFiles, QuizTemporaryFilesService } from 'modules/quiz/services/temporary-files.service';
import { Subject, Unsubscribable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as uuid from 'uuid';

@Component({
  selector: 'edit-quiz-questions',
  templateUrl: './edit-quiz-questions.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class EditQuizQuestionsComponent implements OnInit, OnDestroy, AfterViewInit {
  static readonly selector = 'editQuizQuestions';
  @Input() quiz: IQuiz;
  @Input() formOptions: any;
  @Input() quizTypeId: number;
  @Output() questionsChanged = new EventEmitter<string[]>();
  @Output() errors = new EventEmitter<boolean>();

  @ViewChild('quizQuestionsForm') form: FormGroup;

  currentPage = 1;
  emitter = new Subject<IQuizQuestionEvent>();
  itemsPerPage = 10;
  questionToEdit: Partial<IQuizQuestion> = null;
  settings = this.globalConfig.settings;
  tmpFiles: ITMPFiles;
  questionTypes = [];
  canAddQuestion: boolean;
  questionBankEnabled: boolean;
  questionAnswerEditMode: boolean;
  questionDraft: Partial<IQuizQuestion>;
  activeQuestions: string[] = [];
  subscriber?: Unsubscribable;
  constructor(
    private quizOptionsService: QuizOptionsService,
    private quizTemporaryFilesService: QuizTemporaryFilesService,
    private quizQuestionService: QuizQuestionService,
    private globalConfig: GlobalConfig,
    private quizQuestionManagerService: QuizQuestionsManagerService,
    private notificationService: NotificationService,
  ) {}

  get questionsLimited() {
    return this.quiz.questions.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage);
  }

  ngOnInit() {
    this.tmpFiles = this.quizTemporaryFilesService.getFiles();

    this.subscriber = this.quizOptionsService
      .getQuizQuestionTypes(this.quiz.scoreTypeId !== null, this.quizTypeId)
      .pipe(
        finalize(() => {
          this.subscriber?.unsubscribe();
          delete this.subscriber;
        }),
      )
      .subscribe((questionTypes) => {
        this.questionTypes = questionTypes;
      });

    this.canAddQuestion = this.checkCanAddQuestion();
    this.questionBankEnabled = this.checkQuestionBankEnabled();

    this.prepareQuestions();
  }

  ngAfterViewInit() {
    this.form.statusChanges.subscribe(() => this.errors.emit(this.form.invalid));
    this.formOptions.quizForms.push({ name: 'quizQuestionsForm', form: this.form });
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
    this.quizTemporaryFilesService.clearFiles();
  }

  isQuestionEditable(question: IQuestion) {
    return !question.id || question.quizId === this.quiz.id;
  }

  cancelEdit() {
    this.questionToEdit = null;
    this.questionDraft = null;
  }

  editQuestion(quizQuestion: Partial<IQuizQuestion>) {
    this.questionToEdit = quizQuestion;

    this.questionDraft = _.merge(
      {
        question: this.quizQuestionService.newQuestion({
          targetQuizTypeId: this.quizTypeId,
          quizId: this.quiz.id,
        }),
        weight: 1,
        quizId: this.quiz.id,
      },
      this.questionToEdit,
    );

    this.questionDraft.globalId = this.quiz.globalId;

    return this.questionDraft;
  }

  saveQuestion() {
    this.emitter.next({ event: 'event:quizQuestion:beforeSave', question: this.questionDraft.question });

    if (this.quiz.questions.find((item) => this.questionToEdit === item)) {
      Object.assign(this.questionToEdit, this.questionDraft);
    } else {
      this.quiz.questions.push(_.cloneDeep(this.questionDraft as IQuizQuestion));
    }

    this.cancelEdit();
    this.changeActiveQuestions();
  }

  deleteQuestionItem(item: IQuizQuestion) {
    this.quiz.questions = this.quiz.questions.filter((question) => question !== item);
    this.changeActiveQuestions();
  }

  isEditMode() {
    return !!this.questionToEdit || this.questionAnswerEditMode;
  }

  previewQuestion(question: IQuestion) {
    this.quizQuestionManagerService.previewQuestion(question);
  }

  findQuestion() {
    return this.quizQuestionManagerService.showQuestionSelectionDialog(this.quizTypeId, this.quiz.questions).then(
      (questions) => {
        questions.forEach((question) => {
          const request = this.quizQuestionService
            .get(question.id)
            .pipe(
              finalize(() => {
                request.unsubscribe();
              }),
            )
            .subscribe((response) => {
              this.addQuestionFromSearch(response);
            });
        });
      },
      () => null,
    );
  }

  addQuestionFromSearch(question: IQuestion) {
    const questionAdded = this.quiz.questions.find((quizQuestion) => {
      if (this.settings.quizEdit.questionBank.cloneQuestions) {
        return question.text.toLowerCase() === quizQuestion.question.text.toLowerCase();
      }

      return quizQuestion.question.id === question.id;
    });

    if (questionAdded) {
      this.notificationService.error('The question has already been added.', 3e3);

      return;
    }

    let newQuestion = question;

    if (question.quizId) {
      newQuestion = Object.assign(
        this.quizQuestionService.newQuestion({ targetQuizTypeId: this.quizTypeId }),
        question,
      );
      this.clearQuestionIdentities(newQuestion);
    }

    const newQuizQuestion = {
      question: newQuestion,
      weight: 1,
    };

    this.quiz.questions.push(newQuizQuestion as IQuizQuestion);
    this.currentPage =
      Math.floor(this.quiz.questions.length / this.itemsPerPage) +
      (this.quiz.questions.length % this.itemsPerPage ? 1 : 0);

    if (newQuestion.quizId) {
      this.editQuestion(newQuizQuestion);
    }

    this.changeActiveQuestions();
  }

  editQuestionModal(quizQuestion: IQuizQuestion) {
    return this.quizQuestionManagerService.showInQuizQuestionEditDialog(quizQuestion, this.quiz).then(
      (newData) => {
        Object.assign(quizQuestion, newData);
      },
      () => null,
    );
  }

  moved($event) {
    moveItemInArray(this.quiz.questions, $event.previousIndex, $event.currentIndex);
  }

  reOrder(quizQuestion: IQuizQuestion, upward: boolean) {
    const index = this.quiz.questions.indexOf(quizQuestion);

    if (index < 0 || (upward && index === 0) || (!upward && index === this.quiz.questions.length - 1)) {
      return;
    }

    const newIndex = index + (upward ? -1 : 1);

    this.quiz.questions = this.quiz.questions.filter((q) => q !== quizQuestion);
    this.quiz.questions.splice(newIndex, 0, quizQuestion);
  }

  private clearQuestionIdentities(question: IQuestion) {
    question.id = null;

    question.answerVariants.forEach((variant) => {
      variant.id = null;
    });
  }

  private changeActiveQuestions() {
    this.activeQuestions = this.quiz.questions
      .filter((q) => q.question.active)
      .map((q) => q.question.globalId || q.question.id.toString());

    this.questionsChanged.emit(this.activeQuestions);
  }

  private checkCanAddQuestion() {
    const questionBankSettings = this.settings.quizEdit.questionBank;

    if (!questionBankSettings) {
      return true;
    }

    return (
      !questionBankSettings.quizTypes.includes(this.quizTypeId) || questionBankSettings.allowExclusiveQuizQuestions
    );
  }

  private checkQuestionBankEnabled() {
    const questionBankSettings = this.settings.quizEdit.questionBank;

    if (!questionBankSettings) {
      return false;
    }

    return questionBankSettings.quizTypes.includes(this.quizTypeId);
  }

  private prepareQuestions() {
    if (this.quiz.questions) {
      this.quiz.questions.forEach((quizQuestion) => {
        quizQuestion.question = this.quizQuestionService.newQuestion(quizQuestion.question) as IQuestion;
        quizQuestion.globalId = uuid.v4();
      });
    }

    this.changeActiveQuestions();
  }
}
