import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { IQuizQuestionCategory } from 'modules/admin/quiz/question/category/models/category.model';
import { QuizQuestionsCategoryService } from 'modules/admin/quiz/question/category/services/quiz-questions-category.service';
import { Unsubscribable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'quiz-category-search-filter',
  templateUrl: './category-search-filter.component.html',
})
export class QuizCategorySearchFilterComponent implements OnInit, OnDestroy {
  @Input() useHandler = false;
  @Output() changeHandler = new EventEmitter<number>();

  categoryId: number | string = '';
  topicId = null;
  parentId: number | string = '';
  categories: IQuizQuestionCategory[] = [];
  subCategories: IQuizQuestionCategory[] = [];
  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams<string>;
  private subscriber?: Unsubscribable;
  constructor(
    private quizQuestionCategoryService: QuizQuestionsCategoryService,
    private urlService: UrlService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
  ) {}

  ngOnInit(): void {
    if (!this.useHandler) {
      this.ngOnUrlChange();
      this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
    }

    this.loadCategories();
  }

  ngOnDestroy(): void {
    if (!this.useHandler) {
      this.urlHandlerDestroy();
    }

    this.subscriber?.unsubscribe();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.ngOnUrlParamsChange(params);
    }
  }

  parentChanged(parentId) {
    if (parentId) {
      this.loadSubCategories(parentId);
    } else {
      this.subCategories = [];
    }

    this.categoryId = '';
    this.setSearchQuery();
  }

  categoryChanged() {
    this.setSearchQuery();
  }

  loadCategories() {
    this.subscriber = this.quizQuestionCategoryService
      .query({})
      .pipe(
        finalize(() => {
          this.subscriber.unsubscribe();
          delete this.subscriber;
        }),
      )
      .subscribe((response) => {
        this.categories = _.filter(response.items, (cat) => {
          return cat.active || (!this.useHandler && cat.id === parseInt(this.urlParams.topicId));
        });
      });
  }

  loadSubCategories(parentId) {
    this.subscriber = this.quizQuestionCategoryService
      .query({ parent_id: parentId })
      .pipe(
        finalize(() => {
          this.subscriber.unsubscribe();
          delete this.subscriber;
        }),
      )
      .subscribe((response) => {
        this.subCategories = _.filter(response.items, (subCat) => {
          return subCat.active || subCat.id === this.categoryId;
        });
      });
  }

  setSearchQuery() {
    const categoryId = this.categoryId || this.parentId;

    if (!this.useHandler) {
      const searchQuery = this.urlService.search();

      if (categoryId) {
        searchQuery.topic_id = '' + categoryId;
      } else {
        delete searchQuery.topic_id;
      }

      this.stateService.go(this.activeState.current, searchQuery);
    } else {
      this.topicId = categoryId || null;
      this.changeHandler.emit(this.topicId);
    }
  }

  ngOnUrlParamsChange(params: IUrlParams<string>) {
    if (
      params.topic_id &&
      parseInt(params.topic_id) !== this.categoryId &&
      parseInt(params.topic_id) !== this.parentId
    ) {
      const searchQuery = this.urlService.search();

      if (searchQuery.topic_id) {
        const categoryId = parseInt(searchQuery.topic_id);

        if (categoryId) {
          this.quizQuestionCategoryService.get(categoryId).subscribe((cat) => {
            this.categoryId = categoryId;
            this.parentId = cat.parentId;
          });
        }
      }
    }
  }
}
