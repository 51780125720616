import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { IQuiz } from 'modules/quiz/models/quiz.model';
import { QuizService } from 'modules/quiz/services/quiz.service';
import { Unsubscribable, catchError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'clone-quiz-modal',
  templateUrl: './clone-quiz.component.html',
})
export class CloneQuizComponent implements OnInit, OnDestroy {
  quiz?: IQuiz;
  quizTypeName: string;
  reload = false;
  previewError: string;

  cloneError = '';
  cloning = false;
  reloading = false;
  private subscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private quizService: QuizService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    if (this.reload) {
      this.reloading = true;
      this.subscriber = this.quizService
        .get(this.quiz.id, ['quiz.add'], true)
        .pipe(
          finalize(() => {
            this.subscriber?.unsubscribe();
            delete this.subscriber;
            this.reloading = false;
          }),
          catchError(() => {
            this.previewError = 'Failed to load quiz';

            return null;
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.quiz = result as IQuiz;
          }
        });
    }
  }

  cloneQuiz() {
    this.cloning = true;
    this.subscriber = this.quizService
      .clone(this.quiz.id, true)
      .pipe(
        tap((newQuiz) => {
          this.stateService.go('main.quiz', { id: newQuiz.id });
          this.activeModal.close();
        }),
        catchError((err) => {
          this.cloneError = err;

          return null;
        }),
        finalize(() => {
          this.subscriber.unsubscribe();
          delete this.subscriber;
          this.cloning = false;
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
