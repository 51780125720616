import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { IQuestion } from 'modules/quiz/models/quiz.model';

@Component({
  selector: 'new-quiz-question-version-prompt',
  templateUrl: './question-version-prompt.component.html',
})
export class NewQuizQuestionVersionPromptComponent {
  question: IQuestion;

  constructor(
    private activeModal: NgbActiveModal,
    private stateService: StateService,
  ) {}

  newVersion(): void {
    this.stateService.go('edit.question.quiz', {
      id: this.question.id,
      clone: true,
      parent_id: this.question.id,
      new_version: true,
    });
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
